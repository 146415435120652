import { Table, Carousel } from '@scuf/common';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { SPARE_PARTS } from 'resources';
import { orderAtom } from 'resources/atoms/spareParts';
import ProductInfoModal from './productInfoModal';
import { OrderDTO, OrderItemsDTO } from 'models/spareParts/orderById';
import useFetch from 'helpers/hooks/useFetch';
import Alert from 'components/alert';
import { useSetRecoilState } from 'recoil';
import { notificationAtom } from 'resources';




const ProductAddedPrice: React.FC = () => {
    const [orderData, setOrderData] = useRecoilState<OrderDTO>(orderAtom);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [imagesMap, setImagesMap] = useState<{ [key: string]: string[] }>({});
    const setNotification = useSetRecoilState(notificationAtom);
    const [carregaImagem, setCarregaImagem] = useState<boolean>(false);
    const { t } = useTranslation(['common', 'user', 'home', 'notification', 'spareParts']);
    const canEdit = (): boolean => {
        return orderData.statusId === 2;
    };

    const [, editOrder] = useFetch<OrderDTO>({
        url: SPARE_PARTS.editOrder,
        method: 'POST',
        onError: 'Removed',
        onSuccess: 'Removed'
    });

    const [, fetchImages] = useFetch<string[], any>({
        url: SPARE_PARTS.getImages,
        method: 'POST'
        //onError: t('notification:critical.ErroImage')
        
    });

    
    const getImages = async (partNumbers: string[]): Promise<void> => {
        try {
            await fetchImages(partNumbers, (resp) => {
                const imagesResponse = resp.data;
                const newImagesMap: { [key: string]: string[] } = {};

                let allImagesEmpty = true;

                imagesResponse.forEach((imageData: any) => {
                    if (imageData.partNumber && Array.isArray(imageData.images)) {
                        if (imageData.images.length > 0) {
                            newImagesMap[imageData.partNumber] = imageData.images;
                            allImagesEmpty = false;
                        }
                    }
                });

                if (Object.keys(newImagesMap).length > 0) {
                    setImagesMap((prev) => ({ ...prev, ...newImagesMap }));
                }

                if (allImagesEmpty) {
                    setNotification((old) => ({ ...old, message: 'Erro ao carregar imagem', type: 'critical' }));
                }

                setCarregaImagem(false);
            });

        } catch (error) {
            setNotification({
                type: "critical",
                message: t("notification:critical.ErroImage"),
            });
        }
    };

    useEffect(() => {

        if (carregaImagem) {
            if (orderData && orderData.orderItems) {
                const partNumbers = orderData.orderItems.map(item => item.partNumber!);
                getImages(partNumbers);
            }
        }

    }, [carregaImagem])

    useEffect(() => {

        setCarregaImagem(true);


    }, [orderData.id]);

    const handleDelete = async (itemId: number): Promise<void> => {
        const deleteItem = orderData.orderItems.map((e: OrderItemsDTO) => (itemId === e.id ? { ...e, FlagDeleted: true } : e));
        await editOrder({ ...orderData, orderItems: deleteItem }, current => {
            if (current.data) setOrderData(current.data);
        });
    };

    return (
        <>
            <div className="div-datatable">
                <div>
                    <h2>Produtos Simulados {'(' + orderData?.orderItems.length + ')'} </h2>
                    <Table>
                        {orderData?.orderItems.length > 0 ? (
                            <>
                                <Table.Header>
                                    <Table.HeaderCell content={t('common:translation.Image')} />
                                    <Table.HeaderCell content={t('common:translation.Item')} />
                                    <Table.HeaderCell content={t('common:translation.Description')} />
                                    <Table.HeaderCell content={t('common:translation.Quantity')} />
                                    <Table.HeaderCell content={t('spareparts:translation.ValueNotDiscount')} />
                                    <Table.HeaderCell content={t('common:translation.Discount')} />
                                    <Table.HeaderCell content={t('spareparts:translation.ValueDiscount')} />
                                    <Table.HeaderCell content={t('common:translation.Total')} />
                                </Table.Header>
                                <Table.Body>
                                    {orderData?.orderItems.length > 0 &&
                                        orderData?.orderItems.map((item: any) => (
                                            <>
                                                <Table.Row className="clickable">
                                                    <Table.Cell>
                                                        {imagesMap[item.partNumber]?.length > 0 ? (
                                                            <Carousel
                                                                className="carouselcart"
                                                                autoPlay={true}
                                                                transitionTime={6000}
                                                                height={200}
                                                            >
                                                                {imagesMap[item.partNumber].map((image: string, idx: number) => (
                                                                    <Carousel.Slide
                                                                        key={idx}
                                                                        className="carousel-image"
                                                                        image={`data:image/png;base64,${image}`}
                                                                    />
                                                                ))}
                                                            </Carousel>
                                                        ) : (
                                                            <img
                                                                className="imagepd"
                                                                alt="imagem padrão"
                                                                src={require('../../../../../../assets/HON192.png')}
                                                            />
                                                        )}
                                                    </Table.Cell>
                                                    <Table.Cell>{item?.partNumber}</Table.Cell>
                                                    <Table.Cell width={5}>{item?.description}</Table.Cell>
                                                    <Table.Cell>{item?.quantity}</Table.Cell>
                                                    <Table.Cell>${item?.netPrice?.toFixed(2)}</Table.Cell>
                                                    <Table.Cell>{item?.discount * 100 + '%'}</Table.Cell>
                                                    <Table.Cell>
                                                        ${(item?.netPrice - item?.netPrice * item.discount).toFixed(2)}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        $
                                                        {(
                                                            item?.quantity *
                                                            (item?.netPrice - item?.netPrice * item.discount)
                                                        ).toFixed(2)}
                                                    </Table.Cell>
                                                </Table.Row>
                                                {isModalOpen && (
                                                    <ProductInfoModal
                                                        item={item}
                                                        onClose={() => setModalOpen(false)}
                                                        onDelete={(itemId: number) => {
                                                            canEdit() && handleDelete(itemId);
                                                        }}
                                                        open={isModalOpen}
                                                    />
                                                )}
                                            </>
                                        ))}
                                </Table.Body>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell>{''}</Table.Cell>
                                        <Table.Cell>{''}</Table.Cell>
                                        <Table.Cell>{''}</Table.Cell>
                                        <Table.Cell>{''}</Table.Cell>
                                        <Table.Cell>{t('spareparts:translation.NoTaxIncluded')}</Table.Cell>
                                        <Table.Cell>{t('spareparts:translation.FinalValues')}</Table.Cell>
                                        <Table.Cell>{t('common:translation.Total')}</Table.Cell>
                                        <Table.Cell>${(orderData?.total + orderData.priceFreight)?.toFixed(2)}</Table.Cell>
                                    </Table.Row>
                                </Table.Body>
                            </>
                        ) : (
                            <Alert text={t('common:empty.NotProducts')} />
                        )}
                    </Table>
                </div>
            </div>
        </>
    );
};

export default ProductAddedPrice;
