import { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Header, DatePicker, TextArea } from '@scuf/common';
import { RepairData } from 'models/hsl/general';
import useFetch from 'helpers/hooks/useFetch';
import { companyDtoAtom, HSL, notificationAtom } from 'resources';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { CompanyDTO } from 'models/userManager/addCustomer';
import { ContractDTO, Equipment, SelectOption } from 'models/hsl/general';

interface AddRepairProps {
    onSave: () => void;
    onBack: () => void;
}

const calculateMaximumDeadline = (startDate: Date, days: number): Date => {
    const result = startDate;
    
    let daysAdded = 0;

    while (daysAdded < days) {
        result.setDate(result.getDate() + 1);
        if (result.getDay() !== 0 && result.getDay() !== 6) {
            daysAdded++;
        }
    }
    return result;
};

const InitialData: RepairData = {
    SerialNumber: '',
    TypeService: '',
    PartNumber: '',
    Contract: '',
    CustomerName: '',
    CustomerEmail: '',
    CustomerAddress: '',
    StartDate: new Date(),
    MaxDeadlineDate: '',
    SLAPriority: '',
    ProblemReported: '',
    OtherInfos: '',
    CompanyName: ''
};

const AddRepair: FC<AddRepairProps> = ({ onBack }) => {
    const { t } = useTranslation(['common', 'hsl']);
    const setNotification = useSetRecoilState(notificationAtom);
    const companyInfo = useRecoilValue<CompanyDTO>(companyDtoAtom);

    const [data, setData] = useState<RepairData>(InitialData);

    const [, getContractBySerialNumber, loadingContract] = useFetch<{ serialNumber: string }>({
        url: HSL.getContractBySerialNumber,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    const [, createRepair, loadingCreate] = useFetch<RepairData, RepairData>({
        url: HSL.createRepair,
        method: 'POST',
        onError: t('common:errors.Unexpected')
    });

    useEffect(() => {
        if (data.StartDate && data.SLAPriority) {
            const startDate = new Date(data.StartDate);
            const priorityDays = +data.SLAPriority;
            const newMaxEndDate = calculateMaximumDeadline(startDate, priorityDays);
            setData(prevData => ({
                ...prevData,
                MaxDeadlineDate: newMaxEndDate.toDateString()
            }));
        }
    }, [data.StartDate, data.SLAPriority]);

    const handleSave = async () => {
        const payload = {
            ...data,
            CompanyName: companyInfo.name
        };
        await createRepair(payload, resp => {
            if (resp.data) {
                onBack();
            }
        });
    };

    const checkContractSerialNumber = async (serialNumber: string) => {
        await getContractBySerialNumber({ serialNumber }, resp => {
            if (resp.data) {
                setData(prevData => ({
                    ...prevData,
                    PartNumber: resp.data?.Equipments[0]?.PartNumber,
                    TypeService: resp.data?.Type,
                    Contract: resp.data?.ContractNumber || resp.data?.WarrantyNumber,
                    CustomerName: resp.data?.CustomerName,
                    CustomerAddress: resp.data?.CustomerAddress,
                    CustomerEmail: resp.data?.CustomerEmail,
                    SLAPriority: resp.data?.Sla,
                    AddOn: resp.data?.Equipments[0]?.AddOn,
                    MaterialPartNumber: resp.data?.Equipments[0]?.MaterialPartNumber
                }));
            } else {
                setNotification(old => ({ ...old, type: 'information', message: t('hsl:translation.ContractNotFound') }));
            }
        });
    };

    const handleCheckContractSerialNumber = (serialNumber?: string) => {
        if (serialNumber && serialNumber.length >= 1) {
            checkContractSerialNumber(serialNumber);
        }
    };

    const disableButton = () => {
        return (
            data?.SerialNumber &&
            data?.CustomerName &&
            data?.CustomerEmail &&
            data?.CustomerAddress &&
            data?.ProblemReported &&
            data?.StartDate &&
            data?.SLAPriority &&
            data?.PartNumber &&
            data?.Contract &&
            data?.TypeService
        );
    };

    return (
        <div>
            <Header title={t('pages:repair')} logo={false} menu={false}></Header>
            <div className="form4Columns">
                <Input
                    indicator="required"
                    placeholder={t('common:translation.SerialNumber')}
                    value={data.SerialNumber}
                    onChange={value => {
                        setData(prevData => ({ ...prevData, SerialNumber: value }));
                    }}
                    label={t('common:translation.SerialNumber')}
                    onBlur={() => handleCheckContractSerialNumber(data.SerialNumber)}
                    onEnter={() => handleCheckContractSerialNumber(data.SerialNumber)}
                    loading={loadingContract}
                />
                <Input
                    indicator="required"
                    placeholder={t('hsl:translation.TypeServices')}
                    value={data.TypeService}
                    onChange={value => setData({ ...data, TypeService: value })}
                    label={t('hsl:translation.TypeServices')}
                    disabled={true}
                    loading={loadingContract}
                />
                <Input
                    indicator="required"
                    placeholder={t('hsl:translation.PartNumberEquipment')}
                    value={data.PartNumber}
                    onChange={value => setData({ ...data, PartNumber: value })}
                    label={t('hsl:translation.PartNumberEquipment')}
                    disabled={true}
                    loading={loadingContract}
                />
                <Input
                    indicator="required"
                    placeholder={t('hsl:translation.Contract')}
                    value={data.Contract}
                    onChange={value => setData({ ...data, Contract: value })}
                    label={t('hsl:translation.Contract')}
                    disabled={true}
                    loading={loadingContract}
                />
            </div>
            <div className="form4Columns">
                <Input
                    indicator="required"
                    placeholder={t('hsl:translation.CustomerName')}
                    value={data.CustomerName}
                    onChange={value => setData({ ...data, CustomerName: value })}
                    label={t('hsl:translation.CustomerName')}
                />
                <Input
                    indicator="required"
                    placeholder={t('hsl:translation.CustomerEmail')}
                    value={data.CustomerEmail}
                    onChange={value => setData({ ...data, CustomerEmail: value })}
                    label={t('hsl:translation.CustomerEmail')}
                />
                <Input
                    indicator="required"
                    placeholder={t('hsl:translation.CustomerAddress')}
                    value={data.CustomerAddress}
                    onChange={value => setData({ ...data, CustomerAddress: value })}
                    label={t('hsl:translation.CustomerAddress')}
                />
                <Input
                    indicator="required"
                    placeholder={t('hsl:translation.AddOn')}
                    value={data.AddOn}
                    onChange={value => setData({ ...data, AddOn: value })}
                    disabled={true}
                    label={t('hsl:translation.AddOn')}
                />
            </div>
            <div className="form4Columns">
                <Input
                    indicator="required"
                    placeholder={t('hsl:translation.PartNumberMaterial')}
                    value={data.MaterialPartNumber}
                    onChange={value => setData({ ...data, MaterialPartNumber: value })}
                    disabled={true}
                    label={t('hsl:translation.PartNumberMaterial')}
                />
            </div>
            <div className="form4Columns">
                <TextArea
                    indicator="required"
                    fluid={true}
                    className="textArea"
                    label={t('hsl:translation.ProblemReported')}
                    value={data?.ProblemReported}
                    placeholder={t('hsl:translation.ProblemReported')}
                    onChange={value => setData({ ...data, ProblemReported: value })}
                />
                <TextArea
                    fluid={true}
                    className="textArea"
                    label={t('hsl:translation.OtherInformation')}
                    value={data?.OtherInfos}
                    placeholder={t('hsl:translation.OtherInformation')}
                    onChange={value => setData({ ...data, OtherInfos: value })}
                />
            </div>
            <div className="form4Columns">
                <DatePicker
                    indicator="required"
                    type="date"
                    label={t('hsl:translation.StartDate')}
                    value={data.StartDate}
                    displayFormat={'DD/MM/YYYY'}
                    closeOnDocumentClick={true}
                    disabled={true}
                    onChange={(value: Date) => setData({ ...data, StartDate: value })}
                />
                <Input
                    indicator="required"
                    placeholder={t('hsl:translation.SLAPriority')}
                    label={t('hsl:translation.SLAPriority')}
                    value={data.SLAPriority}
                    disabled={true}
                />
                <DatePicker
                    indicator="required"
                    type="date"
                    label={t('hsl:translation.MaxEndDate')}
                    value={data.MaxDeadlineDate}
                    displayFormat={'DD/MM/YYYY'}
                    closeOnDocumentClick={true}
                    disabled={true}
                    onChange={(value: Date) => setData({ ...data, MaxDeadlineDate: value.toString() })}
                />
            </div>
            <div className="button-hsl">
                <Button
                    content={t('common:actions.Save')}
                    type="primary"
                    onClick={handleSave}
                    className="button-add"
                    loading={loadingCreate}
                    disabled={!disableButton()}
                />
                <Button content={t('common:actions.Back')} type="secondary" onClick={onBack} className="button-back" />
            </div>
        </div>
    );
};

export default AddRepair;
