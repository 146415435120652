import { Clock, WarrantyConsultModal } from 'components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import 'styles/dashboard.scss';
import MenuItem from './menuItem';
import { useEffect, useState } from 'react';
import useUser from 'helpers/hooks/useUser';
import { CompanyDTO } from 'models/userManager/addCustomer';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { COMPANY_MANAGER, companyDtoAtom, satisfactionSurveyAtom } from 'resources';
import { UserModule, UserRole } from 'models/user/sso';
import { SatisfactionSurveyModal } from 'components/satisfactionSurveyModal';
import { getSatisfactionSurvey } from 'resources/api/repaircenter_api/getSatisfactionSuveryList';
import useFetch from 'helpers/hooks/useFetch';
import useFeatureFlag from 'helpers/hooks/useFeatureFlag';

function DashboardMenu() {
    const navigate = useNavigate();
    const { t } = useTranslation(['dashboard', 'common', 'user']);
    const { user: userDetails } = useUser();
    const [companyInfo, setCompanyInfo] = useRecoilState<CompanyDTO>(companyDtoAtom);
    const setSatisfcationSurvey = useSetRecoilState(satisfactionSurveyAtom);
    const userRole = UserRole;
    const userModule = UserModule;
    const [isLoading, setIsLoading] = useState(false);
    const isFeatureHSWLEnabled = useFeatureFlag('HSLWeb');

    const [, getCompanyById] = useFetch<{ id: number | undefined }, CompanyDTO>({
        url: COMPANY_MANAGER.getCompanyById,
        onError: 'LoadInfo'
    });

    const validate = (): boolean => {
        return !!userDetails?.COMPANY;
    };

    useEffect(() => {
        if(!companyInfo){
            validate();
            getUserCompanyInformations();
            satisfactionSurveyAll();
        }
    }, []);

    async function satisfactionSurveyAll() {
        let resp = await getSatisfactionSurvey(userDetails?.SSOID);
        if (!resp.error) {
            setSatisfcationSurvey(resp.data);
        }
    }

    const handleNavigate = async () => {
        if (isLoading) return; 
        setIsLoading(true);

        try {
            await getUserCompanyInformations(); 
            navigate('/spare-parts');
        } catch (error) {
            //---
        } finally {
            setIsLoading(false);
        }
    };

    async function getUserCompanyInformations() {
        await getCompanyById({ id: userDetails?.COMPANY }, current => {
            setCompanyInfo(current.data!);
        });
    }

    return (
        <>
            {<SatisfactionSurveyModal />}
            <div className="container-parent">
                <div className="container-dashboard">
                    <div className="header-menu">
                        <h3>{t('modules.Modules')}</h3>
                        <Clock />
                    </div>
                    <div className="inner-container-dashboard-modules">
                        <MenuItem
                            name={userModule.RMA}
                            buttonContent={<p>{t('modules.repair_center.name')}</p>}
                            tooltipContent={t('modules.repair_center.desc')}
                            iconName="tools"
                            iconRoot="building"
                            onClick={() => navigate('/repair-center')}
                        />
                        <MenuItem
                            name={userModule.SPAREPARTS}
                            buttonContent={t('modules.spare_parts.name')}
                            tooltipContent={t('modules.spare_parts.desc')}
                            iconName="briefcase"
                            iconRoot="common"
                            onClick={handleNavigate}
                            disabled={isLoading}
                        />
                        <MenuItem
                            name={userModule.MAINTENANCE_REPORT}
                            buttonContent={t('modules.maintenance_report.name')}
                            tooltipContent={t('modules.maintenance_report.desc')}
                            iconName="document-report"
                            iconRoot="common"
                            onClick={() => navigate('/maintenance-report')}
                        />
                        <MenuItem
                            name={userModule.HSL_WEB}
                            buttonContent={t('modules.hsl.name')}
                            tooltipContent={t('modules.hsl.desc')}
                            iconName="document-report"
                            iconRoot="common"
                            onClick={() => navigate('/hsl')}
                        />
                    </div>
                    <h3>{t('utilities.Utilities')}</h3>
                    <div className="inner-container-dashboard-utilities">
                        <MenuItem
                            buttonContent={<p>{t('utilities.help.name')}</p>}
                            tooltipContent={t('utilities.help.desc')}
                            iconName="badge-help"
                            iconRoot="common"
                            onClick={() => navigate('/manual')}
                        />
                        <MenuItem
                            buttonContent={t('utilities.certified.name')}
                            tooltipContent={t('utilities.certified.desc')}
                            iconName="buildings"
                            iconRoot="common"
                            onClick={() => navigate('/info/certified')}
                        />
                        <MenuItem
                            buttonContent={t('utilities.warranty.name')}
                            tooltipContent={t('utilities.warranty.desc')}
                            iconName="search"
                            iconRoot="common"
                            onClick={() => navigate('/warranty-consult')}
                        />
                        <MenuItem
                            buttonContent={t('utilities.assistance.name')}
                            tooltipContent={t('utilities.assistance.desc')}
                            iconName="location"
                            iconRoot="common"
                            onClick={() => navigate('/find-assistance')}
                        />
                    </div>

                    <h3>{t('admin_tools.AdminTools')}</h3>
                    <div className="inner-container-dashboard-admintools ">
                        <MenuItem
                            name={userModule.USER}
                            buttonContent={<p>{t('admin_tools.user_manager.name')}</p>}
                            tooltipContent={t('admin_tools.user_manager.desc')}
                            iconName="user-edit"
                            iconRoot="common"
                            onClick={() => navigate('/user')}
                        />
                    </div>
                </div>
                <div className="container2-dashboard">
                    <div className="profile">
                        <div className="info-profile">
                            <div>
                                <h3>{'E-mail'}</h3>
                                <h5>{userDetails?.EMAIL}</h5>
                            </div>
                            <div>
                                <h3>{t('user:address.Company')}</h3>
                                <h5>{companyInfo?.name}</h5>
                            </div>
                            {isFeatureHSWLEnabled ? (
                                <div>
                                    <h3>{'Id SAP'}</h3>
                                    <h5>{companyInfo?.idSap}</h5>
                                </div>
                            ) : (
                                <div>
                                    <h3>{'CNPJ'}</h3>
                                    <h5>{companyInfo?.cnpj}</h5>
                                </div>
                            )}
                            <div>
                                <h3>{t('register:type.Account')}</h3>
                                <p>
                                    {userDetails?.ROLE == userRole.SUPERADMIN.toString() ? (
                                        <h5> Super Admin</h5>
                                    ) : userDetails?.ROLE == userRole.ADMIN.toString() ? (
                                        <h5>{t('user:types.Admin')}</h5>
                                    ) : userDetails?.ROLE == userRole.CLIENTADMIN.toString() ? (
                                        <h5> {t('user:types.ClientAdmin')}</h5>
                                    ) : userDetails?.ROLE == userRole.CLIENT.toString() ? (
                                        <h5>{t('user:types.Client')} </h5>
                                    ) : (
                                        <h5>{t('user:type.Account')} </h5>
                                    )}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="profile-info"></div>
                    <h3>{t('common:actions.Search')}</h3>
                    <WarrantyConsultModal />
                </div>
            </div>
        </>
    );
}
export default DashboardMenu;
