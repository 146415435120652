import { FC, useState, useEffect } from 'react';
import { Button, Input, Select, Icon, Header, DatePicker, Divider, Table } from '@scuf/common';
import { useTranslation } from 'react-i18next';
import { moneyMask, HSL, notificationAtom } from 'resources';
import { Equipment, ContractDTO, FormContractData, SLAPriority } from 'models/hsl/general';
import { RoleComponent } from 'components/roleComponent';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { hslPartsAtom } from 'resources/atoms/hslAtom';
import AddContractByUpload from './addContractByUpload';
import useFetch from 'helpers/hooks/useFetch';

interface AddContractProps {
    onSave: () => void;
    onBack: () => void;
    partner: {
        id: number;
        name: string;
        idSap: string;
        country: string;
    }[];
}

const InitialFormData: FormContractData = {
    Id: undefined,
    ContractId: '',
    IdSap: '',
    Partner: '',
    Country: '',
    Contract: '',
    Value: '',
    ItemNumber: '',
    SerialNumber: '',
    PartNumber: '',
    StartDate: '',
    SaleDate: '',
    EndDate: '',
    Quantity: 0,
    SoldTo: '',
    Customer: '',
    TypeContract: '',
    AddOn: '',
    Sla: '',
    Material: ''
};

const AddContractByForm: FC<AddContractProps> = ({ onSave, onBack, partner }) => {
    const { t } = useTranslation(['common', 'hsl']);
    const setNotification = useSetRecoilState(notificationAtom);
    const [parts, setParts] = useRecoilState(hslPartsAtom);
    const [showUploadContract, setShowUploadContract] = useState<boolean>(false);
    const [data, setData] = useState<FormContractData>(InitialFormData);
    const [equipment, setEquipment] = useState<Equipment>({ SerialNumber: '', PartNumber: '', AddOn: '', ContractId: null, MaterialPartNumber: '' });
    const [equipmentList, setEquipmentList] = useState<Equipment[]>([]);

    const [, createContractByForm, loadingCreate] = useFetch<FormContractData[], ContractDTO>({
        url: HSL.createContractByForm,
        method: 'POST',
        onError: t('notification:critical.Create'),
        onSuccess: t('notification:success.Create')
    });

    const [, getParts] = useFetch({
        url: HSL.getParts,
        method: 'GET',
        onError: t('common:errors.Unexpected')
    });

    useEffect(() => {
        if (!parts.length) {
            getParts(null, (current: any) => {
                if (current.data.length) {
                    setParts(current.data);
                } else {
                    setNotification(old => ({ ...old, type: 'information', message: t('hsl:translation.NotFoundParts') }));
                }
            });
        }
    }, []);


    const handleAddEquipament = () => {
        console.log("MaterialPartNumber antes de adicionar: ", equipment.MaterialPartNumber);
        setEquipmentList([...equipmentList, equipment]);
        setEquipment({ SerialNumber: '', PartNumber: '', AddOn: '', MaterialPartNumber: '', ContractId: null });
    };

    const handleDeleteEquipament = (index: number) => {
        const updatedList = equipmentList.filter((_, i) => i !== index);
        setEquipmentList(updatedList);
    };

    const handleSave = async () => {
        console.log(`##############################: ${data}`)

        const dataList = equipmentList.map(equip => ({
            ...data,
            SerialNumber: equip.SerialNumber!,
            PartNumber: equip.PartNumber,
            AddOn: equip.AddOn,
            MaterialPartNumber: equip.MaterialPartNumber
        }));

        console.log("Dados enviados para a API: ", dataList);

        await createContractByForm(dataList, () => {

            console.log(`##############################: ${dataList}`)
            onSave();
        });

    };

    const isSaveDisabled = () => {
        return (
            !data.Contract ||
            !data.Value ||
            !data.ItemNumber ||
            !data.StartDate ||
            !data.SaleDate ||
            !data.EndDate ||
            data.Quantity === 0 ||
            !data.SoldTo ||
            !data.Customer ||
            !data.Sla ||
            !data.TypeContract ||
            !data.AddOn ||
            equipmentList.length === 0
        );
    };

    const handlePartnerChange = (selectedPartnerName: string) => {
        const selectedPartner = partner.find(p => p.name === selectedPartnerName);
        if (selectedPartner) {
            setData({
                ...data,
                CompanyId: selectedPartner.id,
                Partner: selectedPartner.name,
                IdSap: selectedPartner.idSap,
                PartnerCountry: selectedPartner.country
            });
        }
    };

    return (
        <div>
            {showUploadContract ? (
                <AddContractByUpload onSave={() => setShowUploadContract(false)} onBack={() => setShowUploadContract(false)} />
            ) : (
                <div>
                    <Header title={t('pages:newContract')} logo={false} menu={false}>
                        <div style={{ margin: '10px' }}>
                            <RoleComponent roles={['SUPERADMIN', 'ADMIN']}>
                                <Button
                                    content={t('hsl:translation.ImportContracts')}
                                    type="primary"
                                    size="medium"
                                    iconPosition="right"
                                    icon={<Icon name="file-upload" root="building" />}
                                    onClick={() => setShowUploadContract(true)}
                                    description={t('hsl:translation.ImportContracts')}
                                />
                            </RoleComponent>
                        </div>
                    </Header>
                    <div className="form4Columns">
                        <Select
                            indicator="required"
                            className="select-type"
                            placeholder={t('common:translation.Partner')}
                            label={t('common:translation.Partner')}
                            options={partner.map(p => ({ text: p.name, value: p.name }))}
                            search={true}
                            value={data.Partner}
                            onChange={value => handlePartnerChange(value)}
                        />
                        <Input
                            indicator="required"
                            value={data.Contract}
                            onChange={value => setData({ ...data, Contract: value })}
                            label={t('hsl:translation.ContractNumber')}
                        />
                        <Select
                            indicator="required"
                            className="select-type"
                            placeholder={t('hsl:translation.SLA')}
                            label={t('hsl:translation.SLA')}
                            options={SLAPriority}
                            value={data.Sla}
                            onChange={value => setData({ ...data, Sla: value })}
                        />
                        <Input
                            indicator="required"
                            value={data.TypeContract}
                            onChange={value => setData({ ...data, TypeContract: value })}
                            label={t('hsl:translation.TypeContract')}
                        />
                    </div>
                    <div className="form4Columns">
                        <Input
                            indicator="required"
                            value={moneyMask(data.Value)}
                            onChange={value => setData({ ...data, Value: value })}
                            label={t('hsl:translation.ContractValue')}
                        />
                        <Input
                            indicator="required"
                            value={data.ItemNumber}
                            onChange={value => setData({ ...data, ItemNumber: value })}
                            label={t('hsl:translation.ItemNumber')}
                        />
                        <Input
                            indicator="required"
                            value={data.Material}
                            onChange={value => setData({ ...data, Material: value })}
                            label={t('hsl:translation.PartNumberMaterial')}
                        />
                        <Input
                            indicator="required"
                            type="number"
                            value={data.Quantity.toString()}
                            onChange={value => setData({ ...data, Quantity: Number(value) })}
                            label={t('hsl:translation.Quantity')}
                        />
                    </div>
                    <div className="form4Columns">
                        <Input
                            indicator="required"
                            value={data.SoldTo}
                            onChange={value => setData({ ...data, SoldTo: value })}
                            label={t('hsl:translation.SoldTo')}
                        />
                        <Input
                            indicator="required"
                            value={data.Customer}
                            onChange={value => setData({ ...data, Customer: value })}
                            label={t('hsl:translation.Customer')}
                        />
                        {
                            <Input
                                indicator="required"
                                value={data.AddOn}
                                onChange={value => setData({ ...data, AddOn: value })}
                                label={t('hsl:translation.AddOn')}
                            />
                        }
                        <DatePicker
                            indicator="required"
                            type="date"
                            reserveSpace={false}
                            label={t('hsl:translation.StartDate')}
                            value={data.StartDate}
                            displayFormat={'DD/MM/YYYY'}
                            closeOnDocumentClick={true}
                            onChange={(value: Date) => setData({ ...data, StartDate: value.toDateString() })}
                        />

                        <DatePicker
                            indicator="required"
                            type="date"
                            label={t('hsl:translation.SaleDate')}
                            value={data.SaleDate}
                            displayFormat={'DD/MM/YYYY'}
                            closeOnDocumentClick={true}
                            onChange={(value: Date) => setData({ ...data, SaleDate: value.toDateString() })}
                        />


                        <DatePicker
                            indicator="required"
                            type="date"
                            label={t('hsl:translation.EndDate')}
                            value={data.EndDate}
                            displayFormat={'DD/MM/YYYY'}
                            closeOnDocumentClick={true}
                            onChange={(value: Date) => setData({ ...data, EndDate: value.toDateString() })}
                        />
                    </div>
                    <Divider direction="horizontal"> {t('hsl:translation.AddEquipament')}</Divider>
                    <div className="form3Columns AddEquipment">
                        <Input
                            indicator="required"
                            value={equipment.SerialNumber}
                            onChange={value => setEquipment({ ...equipment, SerialNumber: value })}
                            label={t('common:translation.SerialNumber')}
                        />
                        <Input
                            indicator="required"
                            value={equipment.PartNumber}
                            onChange={value => setEquipment({ ...equipment, PartNumber: value })}
                            label={t('common:translation.PartNumber')}
                        />
                        <Input
                            indicator="required"
                            value={equipment.AddOn}
                            onChange={value => setEquipment({ ...equipment, AddOn: value })}
                            label={t('hsl:translation.AddOn')}
                        />
                        <Input
                            indicator="required"
                            value={equipment.MaterialPartNumber}
                            onChange={value => setEquipment({ ...equipment, MaterialPartNumber: value })}
                            label={t('hsl:translation.PartNumberMaterial')}
                        />
                        <Button
                            className="Add"
                            content={t('common:actions.Add')}
                            type="primary"
                            onClick={handleAddEquipament}
                            disabled={!equipment.PartNumber || !equipment.SerialNumber}

                        />
                    </div>
                    {equipmentList.length > 0 ? (
                        <Table>
                            <Table.Header>
                                <Table.HeaderCell content={t('common:translation.SerialNumber')} />
                                <Table.HeaderCell content={t('common:translation.PartNumber')} />
                                <Table.HeaderCell content={t('hsl:translation.AddOn')} />
                                <Table.HeaderCell content={t('hsl:translation.PartNumberMaterial')} />
                                <Table.HeaderCell content={t('common:actions.Delete')} />
                            </Table.Header>
                            <Table.Body>
                                {equipmentList.map((equip, index) => (
                                    <Table.Row key={index}>
                                        <Table.Cell content={equip.SerialNumber} />
                                        <Table.Cell content={equip.PartNumber} />
                                        <Table.Cell content={equip.AddOn} />
                                        <Table.Cell content={equip.MaterialPartNumber} />
                                        <Table.Cell>
                                            <Icon
                                                name="delete"
                                                root="common"
                                                size="small"
                                                color="critical"
                                                onClick={() => handleDeleteEquipament(index)}
                                                title={t('common:actions.Delete')}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                            </Table.Body>
                        </Table>
                    ) : null}
                    <Divider direction="horizontal" />
                    <div className="button-hsl">
                        <Button
                            content={t('common:actions.Save')}
                            type="primary"
                            onClick={handleSave}
                            className="button-add"
                            loading={loadingCreate}
                            disabled={isSaveDisabled()}
                        />
                        <Button content={t('common:actions.Back')} type="secondary" onClick={onBack} className="button-back" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddContractByForm;