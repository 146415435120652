import { Footer } from '@scuf/common';
import useUser from 'helpers/hooks/useUser';
import jwtDecode from 'jwt-decode';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

const CustomFooter: React.FC = () => {
    const { t } = useTranslation(['common']);
    const navigate = useNavigate();
    const width = window.innerWidth;
    const { user } = useUser();
    return (
        <Footer copyrightText="© 2023 Honeywell International Inc.">
            <div className="miniContainerFooter">
                <h5>{t('translation.Main')}</h5>
                <a href="#" onClick={() => navigate('/info/repair-center')}>
                    {t('common:services.Repairs')}
                </a>
                <a href="#" onClick={() => navigate('/info/contract')}>
                    {t('pages:Contracts')}
                </a>
                <a href="#" onClick={() => navigate('/info/certified')}>
                    {t('common:services.Certified')}
                </a>
            </div>
            <div className="miniContainerFooter">
                <h5>{t('pages:spareParts')}</h5>
                <a href="#" onClick={() => navigate('/info/spare-parts')}>
                    {t('pages:spareParts')}
                </a>
                <a href="#" onClick={() => navigate('/info/solutions')}>
                    {t('services.Solutions')}
                </a>
            </div>
            <div className="miniContainerFooter">
                <h5>{t('services.Warranty')}</h5>
                <a href="#" onClick={() => navigate('/warranty-consult')}>
                    {t('services.WarrantyConsult')}
                </a>
                <a href="https://sps.honeywell.com/br/pt">{t('services.WarrantyMatrix')}</a>
                <a href="#" onClick={() => navigate('/find-assistance')}>
                    {t('services.FindAssistance')}
                </a>
            </div>
            <div className="miniContainerFooter">
                <h5>{t('translation.Contact')}</h5>
                <a href="#" onClick={() => navigate('/info/contact')}>
                    {t('services.Service')}
                </a>
                <a href="#" onClick={() => navigate('/find-assistance')}>
                    {t('services.FindAssistance')}
                </a>
            </div>
            <>
                {user == null && (
                    <div className="miniContainerFooter">
                        <h5>{t('services.MyAccount')}</h5>
                        <a href={process.env.REACT_APP_SSO_LOGIN}>{t('actions.SignIn')}</a>
                        <a href={process.env.REACT_APP_SSO_REGISTER} onClick={() => navigate('/login/sign-up')}>
                            {t('actions.SignUp')}
                        </a>
                        <a href={process.env.REACT_APP_SSO_PASSWORD_RECOVER}>{t('actions.ForgotPassword')}</a>
                    </div>
                )}
            </>

            {width > 845 ? (
                <div className="miniContainerFooter">
                    <h5>{t('translation.Address')}</h5>
                    <ul>Rua Oswaldo Cruz, 615 - Bairro Varginha</ul>
                    <ul>Itajubá - MG - Brasil</ul>
                    <ul>CEP: 37501-168</ul>
                    <ul>CNPJ: 00.954.716/0002-09</ul>
                    <ul>IE: 324.996.037.0079</ul>
                </div>
            ) : (
                <div />
            )}

            <div className="miniContainerFooter1">
                <div className="containerlinks">
                    <Footer.Item href="https://www.honeywell.com/us/en/terms-and-conditions">
                        {t('footer.TermsConditions')}
                    </Footer.Item>
                    <Footer.Item href="https://www.honeywell.com/us/en/cookie-notice">
                        {t('footer.CookieNotice')}
                    </Footer.Item>
                    <Footer.Item
                        href="https://hon-opcenter.my.salesforce-sites.com/PrivacyInformationRequestForm?lang=en">
                        {t('footer.PrivacyChoices')} 
                        <img className="spacefooter" src={require('../../src/assets/privacyoptions29x14.jpg')} alt="Cookies" />
                    </Footer.Item>
                </div>
                <div className='containerprivacydeclaration'>
                    <Footer.Item>
                        {t('footer.InfoProcessPersonal')}
                        <a className="spacefooter" href="https://www.honeywell.com/us/en/privacy-statement">
                            {t('footer.PrivacyDeclaration')}
                        </a>
                        .
                    </Footer.Item>
                </div>
            </div>
        </Footer>
    );
};

export default CustomFooter;
