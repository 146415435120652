export type SelectOption = {
    value: string;
    text: string;
};

export type StatusOption = {
    value: string;
    text: string;
    color: 'red' | 'yellow' | 'orange' | 'green' | 'blue' | 'grey';
};

export const SLAPriority = [
    { value: '1', text: '1' },
    { value: '2', text: '2' },
    { value: '5', text: '5' },
    { value: '15', text: '15' }
];

export type DeliveryData = {
    Id: number;
    Delivery: string;
    IdSap: string;
    ItemId: number;
    PartNumberDescription: string;
    PartNumber: string;
    Quantity: string;
    Billoflading: string;
    BillofladingApprovedDate: string;
    Carrier: string;
    CompanyName?: string;
    Country?: string;
    RegisterDate: string;
    ErrorPartNumber: boolean;
    ErrorDuplicated: boolean;
    PartnerReceived: boolean;
    PartnerReceivedDate: Date;
};

export type DeliveryExcel = {
    Id: number;
    Delivery: string;
    IdSap: string;
    Carrier: string;
    Billoflading: string;
    PartNumber: string;
    Quantity: string;
    ErrorDuplicated: boolean;
    ErrorPartNumber: boolean;
};

export type GuiaData = {
    Id: number;
    IdSap: string;
    GUIA: string;
    State: string;
    ErrorNotExist: boolean;
};

export type DataDetailDeliveryRow = {
    Delivery: string;
    PartNumber: string;
    PartNumberDescription: string;
    Quantity: number;
    PartnerReceived: boolean;
    PartnerReceivedDate: Date;
};

export type GroupedDelivery = {
    Id: number;
    Delivery: string;
    IdSap: string;
    CompanyName?: string;
    Country?: string;
    Carrier: string;
    Billoflading: string;
    BillofladingApprovedDate: string | null;
    RegisterDate: string;
    Items: {
        Id: number;
        PartNumber: string;
        PartNumberDescription: string;
        Quantity: string;
        PartnerReceived: boolean;
        PartnerReceivedDate: Date;
    }[];
};

export type FilterDeliveryDTO = {
    IdSap?: string;
    CompanyName?: string[];
    Country?: string[];
    Delivery?: string;
    BillOfLading?: string;
    Carrier?: string[];
    PartNumber?: string[];
    DateStart: Date | string;
    DateEnd: Date | string;
    PageIndex: number;
    PageSize: number;
};

export interface InventoryData {
    Id: number;
    CompanyName: string;
    ProductName: string;
    PartNumber: string;
    PartNumberDescription: string;
    Quantity: number;
    Actions: ActionDTO[];
}

export type FilterInventoryDTO = {
    CompanyName?: string[];
    Country?: string[];
    PartNumber?: string[];
    PartNumberDescription?: string;
    ProductName?: string[];
    PageIndex: number;
    PageSize: number;
};

export type FilterLogInventoryDTO = {
    ItemInventoryId?: string;
    RegisterDate?: string;
    Observations?: string;
    TrackingNumber?: string;
    Description?: string;
    Action?: string;
    ResponsibleName?: string;
    DateStart: Date | string;
    DateEnd: Date | string;
    PageIndex: number;
    PageSize: number;
};

export type UploadContractData = {
    Id?: number;
    CompanyId?: number;
    ContractId: string;
    IdSap: string;
    Partner?: string;
    PartnerCountry?: string;
    Country?: string;
    Contrato: string;
    Valor: string;
    Partida: string;
    Serie: string;
    Modelo?: string;
    Inicio: string;
    FechaVenda: string;
    Termino: string;
    Cantidad: number;
    SoldTo: string;
    Cliente: string;
    TipoContrato?: string;
    AddOn?: string;
    Sla?: string;
    Material?: string;
};

export type UploadWarrantyData = {
    Id?: number;
    CompanyId?: number;
    Partner?: string;
    IdSap: string;
    Garantia: string;
    Modelo: string;
    Serie?: string;
    Inicio: string;
    Termino: string;
    SoldTo: string;
    Cliente: string;
    Sla?: string;
    Material?: string;
    Country?: string;
};

export type FormContractData = {
    Id?: number;
    CompanyId?: number;
    ContractId: string;
    IdSap: string;
    Partner?: string;
    PartnerCountry?: string;
    Country?: string;
    Contract: string;
    Value: string;
    ItemNumber: string;
    SerialNumber: string;
    PartNumber?: string;
    StartDate: string;
    SaleDate: string;
    EndDate: string;
    Quantity: number;
    SoldTo: string;
    Customer: string;
    TypeContract?: string;
    AddOn?: string;
    Sla?: string;
    Material?: string;
    Model?: string;
};

export type FormWarrantyData = {
    Id?: number;
    CompanyId?: number;
    IdSap: string;
    Partner?: string;
    PartnerCountry?: string;
    Country?: string;
    WarrantyNumber: string;
    SerialNumber: string;
    PartNumber?: string;
    StartDate: string;
    EndDate: string;
    SoldTo: string;
    Customer: string;
    Sla?: string;
    Material?: string;
    Model?: string;
};

export type ReportContractData = {
    Type: string;
    Partner: string;
    Period: {
        to: string;
        from: string;
    };
};

export type ContractDTO = {
    CompanyId?: number;
    Id: number;
    Number?: string;
    ContractNumber?: string;
    WarrantyNumber?: string;
    Status: string;
    Partner: string;
    PartnerCountry?: string;
    IdSap: string;
    CustomerName: string;
    CustomerAddress?: string;
    CustomerCity?: string;
    CustomerCountry?: string;
    CustomerContactName?: string;
    CustomerEmail?: string;
    CustomerPhone?: string;
    EquipmentLocationName?: string;
    EquipmentLocationAddress?: string;
    EquipmentLocationCity?: string;
    EquipmentLocationCountry?: string;
    SaleDate?: string;
    StartDate: string;
    EndDate: string;
    InactivateDate?: string;
    ContractValue?: string;
    HourlyContractValue?: string;
    WarrantyHourlyValue?: string;
    CustomerNumber?: string;
    TrackingNumber?: string;
    PurchaseOrder?: string;
    SiteCount?: number;
    Description?: string;
    Comments?: string;
    Type: string;
    Sla?: string;
    Quantity?: number;
    SoldTo?: string;
    Model?: string;
    ItemNumber?: string;
    TypeContract?: string;
    MaterialPartNumber?: string;
    AddOn?: string;
    Equipments: Equipment[];
};

export interface FilterContractDTO {
    CompanyId?: number;
    Partner?: string[];
    Country?: string[];
    PartNumber?: string[];
    IdSap?: string;
    Type?: string;
    Status?: string;
    SLAPriority?: string;
    ContractNumber?: string;
    WarrantyNumber?: string;
    CompanyName?: string;
    CustomerName?: string;
    SerialNumber?: string;
    DateStart?: string;
    DateEnd?: string;
    SaleDate?: string;
    PageIndex: number;
    PageSize: number;
}

export type RepairDTO = {
    SerialNumber?: string;
    TypeService?: string;
    CompanyName?: string;
    PartNumber?: string;
    Contract?: string;
    CustomerName?: string;
    CustomerAddress?: string;
    CustomerEmail?: string;
    StartDate?: string;
    SLAPriority?: number;
    MaxDeadlineDate?: string;
    ProblemReported?: string;
    OtherInfos?: string;
    EndDate?: string;
    WorkingTime?: string;
    RepairDescription?: string;
    Status?: number;
    Misuse?: boolean;
    ResponsibleTech?: string;
    ResponsibleUser?: string;
};

export interface FilterRepairDTO {
    Id?: string | number;
    Misuse?: boolean | null;
    CompanyName?: string[];
    Country?: string[];
    Client?: string;
    PartNumber?: string[];
    SerialNumber?: string;
    TypeService?: string;
    ResponsibleUser?: string;
    ResponsibleTech?: string;
    Status?: number | string;
    PageIndex: number;
    PageSize: number;
}

export type RepairData = {
    SerialNumber?: string;
    TypeService: string;
    PartNumber: string;
    Contract: string;
    CustomerName?: string;
    CustomerEmail?: string;
    CustomerAddress?: string;
    StartDate?: Date;
    MaxDeadlineDate?: string;
    SLAPriority?: string;
    ProblemReported?: string;
    OtherInfos?: string;
    CompanyName?: string;
    AddOn?: string;
    MaterialPartNumber?: string;
};

export type RepairAction = {
    EndDate?: string;
    WorkingTime?: string;
    RepairDescription?: string;
    Misuse?: boolean;
    Status?: number;
};

export type RepairPartsUsed = {
    IdPart: string;
    Description?: string;
    Price: string;
    Available: string;
    Quantity: string;
    Pendent: string;
};

export type RepairPartsDTO = {
    Id?: string;
    PartNumberDescription?: string;
    PartNumber?: string;
    ProductName?: string;
    Quantity?: string;
    ResponsibleName?: string;
};

export interface ItemInventoryPartDetailsDTO {
    Id?: number;
    CompanyId?: number;
    CompanyName?: string;
    CompanyCountry?: string;
    ProductName?: string;
    PartNumberDescription?: string;
    PartNumber?: string;
    FinalPrice: string;
    ListPrice: string;
    Quantity: number;
    Available?: number;
    ResponsibleName?: string;
    ResponsibleId?: string;
    RepairId: number;
    ItemInventoryId?: string;
    Pendent: boolean;
    PendingItemInventoryId?: number;
}

export type ActionDTO = {
    ItemInventoryId?: number;
    Action?: string;
    Quantity?: number;
    Description?: string;
    TrackingNumber?: string;
    Observation?: string;
};

export type FilterQuotationDTO = {
    Id?: string | number;
    Partner?: string[];
    CompanyId?: string;
    TotalPrice?: string;
    RegistrationDate?: string;
    PageIndex: number;
    PageSize: number;
};

export type PartQuotation = {
    Id?: string;
    PartNumber: string;
    PartNumberDescription: string;
    PriceItem: number;
    Quantity: number;
    TotalPriceItem: number;
    TotalPrice?: string;
};

export type QuotationDTO = {
    Id?: string;
    Partner: string;
    IdSap: string;
    CompanyId: number;
    RegistrationDate?: Date;
    Items: PartQuotation[];
};

export type ListQuotations = {
    Partner?: string;
    PartnerIdSap?: string;
    ResponsibleCompanyId?: number;
    PartNumber: string;
    Description: string;
    ListPrice: number;
    Quantity: number;
    TotalPriceItem: number;
    TotalPrice?: number;
    QuotationNumber?: number;
};

export interface Part {
    PartNumber?: string;
    Description?: string;
}

export interface Product {
    Name: string;
    Description?: string;
}

export interface FilterProductPartsDTO {
    NameProducts: [];
    ProductDescription?: string;
    PartNumbers?: [];
    PartNumberDescription?: string;
    FinalPrice: string;
    ListPrice: string;
    PageIndex: number;
    PageSize: number;
}

export interface ProductsPartsDTO {
    Id: number;
    ProductId: number;
    NameProduct: string;
    ProductDescription: string;
    PartId: number;
    PartNumber: string;
    PartDescription: string;
    FinalPrice: string;
    ListPrice: string;
}

export interface Equipment {
    MaterialPartNumber?: string;
    PartNumber?: string;
    SerialNumber?: string;
    ContractId: number | null;
    ErrorDuplicated?: boolean;
    NumberDuplicated?: string;
    AddOn?: string;
}
