import { Table, Carousel } from '@scuf/common';
import { formatDate } from 'helpers';
import { OrderDTO } from 'models/spareParts/orderById';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { orderAtom } from 'resources/atoms/spareParts';
import useFetch from 'helpers/hooks/useFetch';
import { SPARE_PARTS } from 'resources';
import { useSetRecoilState } from 'recoil';
import { notificationAtom } from 'resources';
import { useRef } from 'react';

const ProductAddedWaranty: React.FC = () => {
    const [orderData] = useRecoilState<OrderDTO>(orderAtom);
    const { t } = useTranslation(['spareparts', 'common', 'notification']);
    const setNotification = useSetRecoilState(notificationAtom);
    const [imagesMap, setImagesMap] = useState<{ [key: string]: string[] }>({});
    const [carregaImagem, setCarregaImagem] = useState<boolean>(false);
    const loadedPartsRef = useRef<Set<string>>(new Set());

    const [, fetchImages] = useFetch<string[], any>({
        url: SPARE_PARTS.getImages,
        method: 'POST'
        //onError: t('notification:critical.ErroImage')
    });

    const getImages = async (partNumbers: string[]): Promise<void> => {
        try {
            await fetchImages(partNumbers, (resp) => {
                const imagesResponse = resp.data;
                const newImagesMap: { [key: string]: string[] } = {};

                let allImagesEmpty = true;

                imagesResponse.forEach((imageData: any) => {
                    if (imageData.partNumber && Array.isArray(imageData.images)) {
                        if (imageData.images.length > 0) {
                            newImagesMap[imageData.partNumber] = imageData.images;
                            allImagesEmpty = false;
                        }
                    }
                });

                if (Object.keys(newImagesMap).length > 0) {
                    setImagesMap((prev) => ({ ...prev, ...newImagesMap }));
                }

                if (allImagesEmpty) {
                    setNotification((old) => ({ ...old, message: 'Erro ao carregar imagem', type: 'critical' }));
                }

                setCarregaImagem(false);
            });

        } catch (error) {
            setNotification({
                type: "critical",
                message: t("notification:critical.ErroImage"),
            });
        }
    };

    useEffect(() => {

        if (carregaImagem) {
            if (orderData && orderData.orderItems) {
                const partNumbers = orderData.orderItems.map(item => item.partNumber!);
                getImages(partNumbers);
            }
        }

    }, [carregaImagem])

    useEffect(() => {

        setCarregaImagem(true);
        

    }, [orderData.id]);

    return (
        <>
            <div className="div-datatable">
                <div>
                    <h2>
                        {t('spareparts:translation.SimulatedProducts')} {'(' + orderData?.orderItems.length + ')'}{' '}
                    </h2>
                    <Table>
                        <Table.Header>
                            <Table.HeaderCell content={t('common:translation.Image')} />
                            <Table.HeaderCell content={t('common:translation.Item')} />
                            <Table.HeaderCell content={''} />
                            <Table.HeaderCell content={t('common:translation.Quantity')} />
                            <Table.HeaderCell content={t('spareparts:translation.ValueNotDiscount')} />
                            <Table.HeaderCell content={t('common:translation.Discount')} />
                            <Table.HeaderCell content={t('spareparts:translation.ValueDiscount')} />
                            <Table.HeaderCell content={t('common:translation.Total')} />
                        </Table.Header>
                        <Table.Body>
                            {orderData
                                ? orderData?.orderItems?.map((item: any) => (
                                    <Table.Row key={item.partNumber}>
                                        <Table.Cell>
                                            {imagesMap[item.partNumber]?.length > 0 ? (
                                                <Carousel
                                                    className="carouselcart"
                                                    autoPlay={true}
                                                    transitionTime={6000}
                                                    height={200}
                                                >
                                                    {imagesMap[item.partNumber].map((image: string, idx: number) => (
                                                        <Carousel.Slide
                                                            key={idx}
                                                            className="carousel-image"
                                                            image={`data:image/png;base64,${image}`}
                                                        />
                                                    ))}
                                                </Carousel>
                                            ) : (
                                                <img
                                                    className="imagepd"
                                                    alt="imagem padrão"
                                                    src={require('../../../../../../assets/HON192.png')}
                                                />
                                            )}
                                        </Table.Cell>
                                        <Table.Cell>{item?.partNumber} </Table.Cell>
                                        <Table.Cell width={5}> {item?.description} </Table.Cell>
                                        <Table.Cell>{item?.quantity} </Table.Cell>
                                        <Table.Cell>${item?.netPrice?.toFixed(2)} </Table.Cell>
                                        <Table.Cell>{item?.discount * 100 + '%'} </Table.Cell>
                                        <Table.Cell>
                                            ${(item?.netPrice - item.netPrice * item?.discount).toFixed(2)}{' '}
                                        </Table.Cell>
                                        <Table.Cell>
                                            ${((item?.netPrice - item.netPrice * item?.discount) * item?.quantity).toFixed(2)}{' '}
                                        </Table.Cell>
                                    </Table.Row>
                                ))
                                : ''}
                        </Table.Body>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>{''}</Table.Cell>
                                <Table.Cell>{''} </Table.Cell>
                                <Table.Cell>{''} </Table.Cell>
                                <Table.Cell>{''} </Table.Cell>
                                <Table.Cell>{t('spareparts:translation.NoTaxIncluded')} </Table.Cell>
                                <Table.Cell>{t('spareparts:translation.FinalValues')} </Table.Cell>
                                <Table.Cell>{t('common:translation.Total')} </Table.Cell>
                                <Table.Cell>${(orderData?.total + orderData.priceFreight).toFixed(2)}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </div>
            </div>
        </>
    );
};

//const ProductAddedWaranty: React.FC = () => {
//    const [orderData, setOrderData] = useRecoilState<OrderDTO>(orderAtom);
//    const setNotification = useSetRecoilState(notificationAtom);
//    const [carregaImagem, setCarregaImagem] = useState<boolean>(false);
//    const [imagesMap, setImagesMap] = useState<{ [key: string]: string[] }>({}); 
//    const [isModalOpen, setModalOpen] = useState<boolean>(false);
//
//    const { t } = useTranslation(['common', 'user', 'home', 'notification', 'spareParts']);
//    const canEdit = (): boolean => {
//        return orderData.statusId === 2;
//    };
//    const [, editOrder] = useFetch<OrderDTO>({
//        url: SPARE_PARTS.editOrder,
//        method: 'POST',
//        onError: 'Removed',
//        onSuccess: 'Removed'
//    });
//
//
//    const [, fetchImages] = useFetch<{ partNumbers: string[] }, any>({
//        url: SPARE_PARTS.getImages,
//        method: 'POST'
//        //onError: t('notification:critical.ErroImage')
//    });
//
//    const getImages = async (partNumbers: string[]): Promise<void> => {
//        try {
//            await fetchImages({ partNumbers }, (resp) => {
//                const imagesResponse = resp?.data ?? [];
//                const newImagesMap: { [key: string]: string[] } = {};
//
//                let allImagesEmpty = true;
//
//                imagesResponse.forEach((imageData: any) => {
//                    if (imageData.partNumber && Array.isArray(imageData.images)) {
//                        if (imageData.images.length > 0) {
//                            newImagesMap[imageData.partNumber] = imageData.images;
//                            allImagesEmpty = false;
//                        }
//                    }
//                });
//
//                console.log("Resposta da API:", imagesResponse);
//                console.log("Novo imagesMap:", newImagesMap);
//
//                if (Object.keys(newImagesMap).length > 0) {
//                    setImagesMap((prev) => ({ ...prev, ...newImagesMap }));
//                    console.log("Imagens carregadas com sucesso!");
//                }
//
//                if (allImagesEmpty) {
//                    console.warn("Todas as imagens retornadas estão vazias!");
//                    setNotification((old) => ({ ...old, message: 'vai batata!!!!!', type: 'critical' }));
//                }
//
//                setCarregaImagem(false);
//            });
//
//        } catch (error) {
//            console.error("Erro ao buscar imagens:", error);
//            setNotification({
//                type: "critical",
//                message: t("notification:critical.ErroImage"),
//            });
//        }
//    };
//
//    useEffect(() => {
//
//        if (carregaImagem) {
//            console.log(`Carregar imagem`);
//            if (orderData && orderData.orderItems) {
//                const partNumbers = orderData.orderItems.map(item => item.partNumber!);
//                getImages(partNumbers);
//            }
//        }
//        else
//            console.log(`Deixa pra lá: imagem`);
//
//    }, [carregaImagem])
//
//    useEffect(() => {
//
//        setCarregaImagem(true);
//        console.log(`Chamando UseEffect ${JSON.stringify(orderData)}`);
//
//    }, [orderData.id]);
//
//    return (
//        <>
//            <div className="div-datatable">
//                <div>
//                    <h2>
//                        {t('translation.AddedProducts')} {'(' + orderData?.orderItems.length + ')'}{' '}
//                    </h2>
//                    <Table>
//                        <Table.Header>
//                            <Table.HeaderCell content={t('common:translation.Image')} />
//                            <Table.HeaderCell content={t('common:translation.Item')} />
//                            <Table.HeaderCell content={''} />
//                            <Table.HeaderCell content={t('translation.EquipSerial')} />
//                            <Table.HeaderCell content={t('translation.SerialPart')} />
//                            <Table.HeaderCell content={t('common:translation.Date')} />
//                            <Table.HeaderCell content={t('common:translation.Problem')} />
//                        </Table.Header>
//                        <Table.Body>
//                            {orderData?.orderItems &&
//                                orderData?.orderItems?.map((item: any) => (
//                                    <Table.Row>
//                                        <Table.Cell>
//                                            {imagesMap[item.partNumber]?.length > 0 ? (
//                                                <Carousel
//                                                    className="carouselcart"
//                                                    autoPlay={true}
//                                                    transitionTime={6000}
//                                                    height={200}
//                                                >
//                                                    {imagesMap[item.partNumber].map((image: string, idx: number) => (
//                                                        <Carousel.Slide
//                                                            key={idx}
//                                                            className="carousel-image"
//                                                            image={`data:image/png;base64,${image}`}
//                                                        />
//                                                    ))}
//                                                </Carousel>
//                                            ) : (
//                                                <img
//                                                    className="imagepd"
//                                                    alt="imagem padrão"
//                                                    src={require('../../../../../../assets/HON192.png')}
//                                                />
//                                            )}
//                                        </Table.Cell>
//                                        <Table.Cell>{item.partNumber}</Table.Cell>
//                                        <Table.Cell>{item.description} </Table.Cell>
//                                        <Table.Cell>{item.serialNumberEquipment} </Table.Cell>
//                                        <Table.Cell>{item.serialNumberPart} </Table.Cell>
//                                        <Table.Cell>{formatDate(item.createdAt)} </Table.Cell>
//                                        <Table.Cell>{item.descriptionDefectFound} </Table.Cell>
//                                    </Table.Row>
//                                ))}
//                        </Table.Body>
//                    </Table>
//                </div>
//            </div>
//        </>
//    );
//};

export default ProductAddedWaranty;